import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    // redirect: '/Home/PublishRecruit',//路由重定向
    redirect: "/Login", //路由重定向
  },
  {
    path: "/QRCode",
    component: () => import("../views/ShowQRCode.vue"),
  },
  {
    path: "/ShowCode",
    component: () =>
      import("../components/secondPermission/ShowQRcode.vue"),
  },
  {
    path: "/Login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/CreateRecruit",
    component: () => import("../views/firstPermisson/CreateRecruitView.vue"),
  },
  {
    path: "/FirstPermission",
    component: () => import("../views/firstPermisson/FirstHomeView.vue"),
    children: [
      {
        path: "ViewResume",
        component: () => import("../components/firstPermission/ViewResume.vue"),
      },
      {
        path: "ConfirmResume",
        component: () =>
          import("../components/firstPermission/ConfirmResume.vue"),
      },
      {
        path: "ResumeDetail",
        component: () =>
          import("../components/firstPermission/ResumeDetail.vue"),
      },
      {
        path: "InterviewArrangement",
        component: () =>
          import("../components/firstPermission/InterviewArrangement.vue"),
        children: [
          {
            path: "AnnouncementTime",
            component: () =>
              import(
                "../components/firstPermission/arrangement/AnnouncementTime.vue"
              ),
          },
          {
            path: "InterviewReview",
            component: () =>
              import(
                "@/components/firstPermission/arrangement/InterviewReview.vue"
              ),
          },
          {
            path: "PublishCheckin",
            component: () =>
              import(
                "@/components/firstPermission/arrangement/PublishCheckin.vue"
              ),
          },
        ],
      },
      {
        path: "MemberManagement",
        component: () =>
          import("../components/firstPermission/MemberManagement.vue"),
      },
      {
        path: "RecruitmentRecords",
        component: () =>
          import("../components/firstPermission/RecruitmentRecords.vue"),
      },
      {
        path: "GlobalSettings",
        component: () =>
          import("../components/firstPermission/GlobalSettings.vue"),
      },
    ],
  },
  {
    path: "/SecondPermission",
    component: () => import("../views/secondPermisson/SecondHomeView.vue"),
    children: [
      {
        path: "ViewResume",
        component: () => import("@/components/secondPermission/ViewResume.vue"),
      },
      {
        path: "ResumeDetail",
        component: () =>
          import("../components/secondPermission/ResumeDetail.vue"),
      },
      {
        path: "InterviewComment",
        component: () =>
          import("../components/secondPermission/InterviewComment.vue"),
      },
      {
        path: "InterviewProgress",
        component: () =>
          import("@/components/secondPermission/InterviewProgress.vue"),
      },
      {
        path: "PostCheckIn",
        component: () =>
          import("@/components/secondPermission/PostCheckIn.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: routes,
});

export default router;
